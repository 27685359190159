html, 
body,
#root,
.app,
.content {
    margin: 0;
    padding: 0;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    width:100%;
    height:100%;
}

.app {
    display: flex;
}

::-webkit-scrollbar {
    width:10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
    background: #555;
}

#link {
    text-decoration: none;
    color: rgb(46,46,46);
}




/*------ Nav -----*/

/* nav {
    background-color: rgb(43,43,63);
    color:#fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

nav .logo-container,
nav .controls-container {
    padding:40px 25px;
    display:flex;
    flex-direction: column;
}

nav .logo-container img {
    width: 50px;
}

nav .icon {
    color: #fff;
}
 */

/* ----- Dashboard ----- */

/* .dashboard {
    padding: 30px;
    width: 100%;
}*/

.ticket-container {
    height: 75vh;
    overflow : scroll;
}
 

/* ----- Ticket Card ----- */

 .ticket-card {
    display:flex;
    width:100%;
    height:80px;
}

.ticket-card #link {
    display:flex;
    width:100%;
}

.ticket-card #link > * {
    background-color: rgb(221,221,221);
    margin: 2px;
    padding: 30px;
    width: 100%;
    display: flex;
    align-items: center;
}

.ticket-card .ticket-color{
    width:15px !important;
    padding: 10px !important;
    margin: 2px;
}

.ticket-card .avatar-container {
    display : flex;
    justify-content: center;
    width:88px;
}

.ticket-card .img-container {
    height: 50px;
    width:50px;
    border-radius: 25px;
    overflow: hidden;
}

.ticket-card .img-container img {
    width: 100%;
}

.ticket-card .status-display {
    display: flex;
    justify-content: center;
}

.ticket-card .priority-display {
    display: flex;
    justify-content: center;
}

.ticket-card .priority-display .star-container {
    display: flex;
}

.ticket-card .priority-display .star-container h3{
    margin: 7px;
    padding: 0;
}

.ticket-card .progress-display{
    min-width: 200px;
}

.ticket-card .progress-bar {
    width:100%;
    height: 30px;
    background-color: rgb(158,158,158);
    border-radius: 15px;
    overflow: hidden;
}

.ticket-card .progress-bar .progress-indicator {
    background-color: #4cceac;
    height: 100%;
}

.ticket-card .delete-block {
    background-color: rgb(211,211,211);
    width: 50px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ticket-card .delete-block .delete-icon {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: rgb(61,61,61);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255,255,255);
}





/* ------- Ticket Page -----------*/

.ticket form section {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 500px;
}

.ticket form label {
    margin: 20px 0 0 0;
}

.ticket form .ticket-select,
.ticket form .ticket-input {
    padding: 10px;
    font-size: 15px;
    border-radius: 10px;
    border: 1.5px solid rgb(218,218,218);
    margin: 5px;
}




/* ---- Shake Animation ----- */

 .shake {
    animation: shake 1s infinite;
  }
  @keyframes shake {
    0% {
      transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-2deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(3deg);
    }
    30% {
      transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(2px, 1px) rotate(-2deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(4deg);
    }
    90% {
      transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }